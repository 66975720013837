<template>
	<div class="pr-20 flex-column full-height bg-base  size-px-16 overflow-y-auto">
		<div class="">
			<div class="justify-space-between gap-20 font-weight-bold ">
				<div class="bg-white  pa-20 text-center">
					<div class="color-primary ">TODAY</div>
					<div class="mt-10 size-px-20">{{ search.sDate }}</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">회원 가입 현황</div>
					<div class="mt-10 size-px-14">
						<template
							v-for="item in items"
						>
							<div
								:key="'join_' + item.site_id"
								class="justify-space-between"
							>
								{{ item.site_name }}
								<span>{{ item.item.join_today | makeComma}} / {{ item.item.total_join | makeComma}} 명</span>
							</div>
						</template>

					</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">충전주문</div>
					<div class="mt-10 size-px-14">
						<template
							v-for="item in items"
						>
							<div
								:key="'join_' + item.site_id"
								class="justify-space-between"
							>
								{{ item.site_name }}
								<span>{{ item.item.total_charge | makeComma }}건 / {{ item.item.total_charge_price | makeComma }}원</span>
							</div>
						</template>
					</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">일반 상품 주문 </div>
					<div class="mt-10 size-px-14">
						<template
							v-for="item in items"
						>
							<div
								:key="'join_' + item.site_id"
								class="justify-space-between"
							>
								{{ item.site_name }}
								<span>{{ item.item.total_order_normal | makeComma }}건 / {{ item.item.total_order_normal_price | makeComma }}원</span>
							</div>
						</template>
					</div>
				</div>
				<div class="bg-white flex-1 pa-20 text-center">
					<div class="color-primary ">기업 상품 주문</div>
					<div class="mt-10 size-px-14">
						<template
							v-for="item in items"
						>
							<div
								:key="'join_' + item.site_id"
								class="justify-space-between"
							>
								{{ item.site_name }}
								<span>{{ item.item.total_order_business | makeComma }}건 / {{ item.item.total_order_business_price | makeComma }}원</span>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="mt-20">
				<div class="justify-space-between">
					<GChart
						type="ColumnChart"
						:data="chart_data_1"
						:options="chartOptions1"
						class="flex-1 mr-10 box"
					/>
					<GChart
						type="ColumnChart"
						:data="chart_data_2"
						:options="chartOptions2"
						class="flex-1 mr-10 box"
					/>
					<GChart
						type="ColumnChart"
						:data="chart_data_3"
						:options="chartOptions3 "
						class="flex-1 box"
					/>
				</div>
			</div>

			<div class="mt-20 bg-white pa-10">
				<div><span class="font-weight-bold">주문 현황</span> <span class="color-gray-light size-px-14 hidden">최근 1개월 이내</span></div>
				<table class="table mt-15">
					<colgroup>

					</colgroup>
					<thead>
					<tr>
						<th colspan="2">구분</th>
						<th>결제완료</th>
						<th>배송전</th>
						<th>배송중</th>
						<th>배송완료</th>
					</tr>
					</thead>
					<tbody>
					<template
						v-for="(item, i_index) in items"
					>
						<template
							v-for="(order, o_index) in item.items_order"
						>
						<tr
							v-if="order.type_name == '일반 상품'"
							:key="'order_' + i_index + o_index"
						>
							<td
								v-if="i_index == 0"
								:rowspan="items.length"
							>일반 상품</td>
							<td>{{ item.site_name }}</td>
							<td>{{ order.order_count | makeComma}} 건</td>
							<td>{{ order.odt1_count | makeComma}} 건</td>
							<td>{{ order.odt2_count | makeComma}} 건</td>
							<td>{{ order.odt3_count | makeComma}} 건</td>
						</tr>
						</template>
					</template>
					<template
						v-for="(item, i_index) in items"
					>
						<template
							v-for="(order, o_index) in item.items_order"
						>
							<tr
								v-if="order.type_name == '기업 상품'"
								:key="'order_' + i_index + o_index"
							>

								<td
									v-if="i_index == 0"
									:rowspan="items.length"
								>기업 상품</td>
								<td>{{ item.site_name }}</td>
								<td>{{ order.order_count | makeComma}} 건</td>
								<td>{{ order.odt1_count | makeComma}} 건</td>
								<td>{{ order.odt2_count | makeComma}} 건</td>
								<td>{{ order.odt3_count | makeComma}} 건</td>
							</tr>
						</template>
					</template>
					</tbody>
				</table>
			</div>

			<div class="mt-15 justify-space-between gap-20 ">
				<div class="bg-white pa-10 flex-1 ">

					<div><span class="font-weight-bold">충전 현황</span> <span class="color-gray-light size-px-14 hidden">최근 1개월 이내</span></div>
					<div class="mt-10 justify-space-between gap-20 items-center text-center">
						<div class="flex-1 box">
							<div class="bg-title font-weight-bold pa-10 under-line size-px-12">충전 주문건</div>
							<div class="ptb-30">
								<template
									v-for="item in items"
								>
									<div
										:key="'join_' + item.site_id"
										class="justify-space-between size-px-14 pa-10"
									>
										<span>{{ item.site_name }}</span>
										{{ item.item_charge.charge_count | makeComma}} 건
									</div>
								</template>
								<div class="justify-space-between size-px-14 pa-10">
									<span>합계</span>
									{{ item_total.charge_count | makeComma}} 건
								</div>
							</div>
						</div>
						<div class="flex-1 box">
							<div class="bg-title font-weight-bold pa-10 under-line size-px-12">충전 요청 금액</div>
							<div class="ptb-30">
								<template
									v-for="item in items"
								>
									<div
										:key="'join_' + item.site_id"
										class="justify-space-between size-px-14 pa-10"
									>
										<span>{{ item.site_name }}</span>

										{{ item.item_charge.charge_price | makeComma}} 원
									</div>
								</template>
								<div class="justify-space-between size-px-14 pa-10">
									<span>합계</span>
									{{ item_total.charge_price | makeComma}} 원
								</div>
							</div>
						</div>
						<div class="flex-1 box">
							<div class="bg-title font-weight-bold pa-10 under-line size-px-12">충전 완료 금액</div>
							<div class="ptb-30">
								<template
									v-for="item in items"
								>
									<div
										:key="'join_' + item.site_id"
										class="justify-space-between size-px-14 pa-10"
									>
										<span>{{ item.site_name }}</span>

										{{ item.item_charge.charge_order_price | makeComma}} 원
									</div>
								</template>
								<div class="justify-space-between size-px-14 pa-10">
									<span>합계</span>
									{{ item_total.charge_order_price | makeComma }} 원
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="bg-white pa-10 flex-1 ">
					<div><span class="font-weight-bold">클레임 현황</span> <span class="color-gray-light size-px-14 hidden">최근 1개월 이내</span></div>
					<table class="table mt-10">
						<colgroup>

						</colgroup>
						<thead>
						<tr>
							<th colspan="2">구분</th>
							<th>취소 요청</th>
							<th>반품 요청</th>
							<th>교환 요청</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-for="(item, i_index) in items"
						>
							<template
								v-for="(claim, c_index) in item.items_claim"
							>
								<tr
									v-if="claim.type_name == '일반 상품'"
									:key="'claim_' + i_index + c_index"
								>
									<td
										v-if="i_index == 0"
										:rowspan="items.length"
									>일반 상품</td>
									<td>{{ item.site_name }}</td>
									<td>{{ claim.cancel_count | makeComma}} 건</td>
									<td>{{ claim.return_count | makeComma}} 건</td>
									<td>{{ claim.change_count | makeComma}} 건</td>
								</tr>
							</template>
						</template>
						<template
							v-for="(item, i_index) in items"
						>
							<template
								v-for="(claim, c_index) in item.items_claim"
							>
								<tr
									v-if="claim.type_name == '기업 상품'"
									:key="'claim_' + i_index + c_index"
								>
									<td
										v-if="i_index == 0"
										:rowspan="items.length"
									>기업 상품</td>
									<td>{{ item.site_name }}</td>
									<td>{{ claim.cancel_count | makeComma}} 건</td>
									<td>{{ claim.return_count | makeComma}} 건</td>
									<td>{{ claim.change_count | makeComma}} 건</td>
								</tr>
							</template>
						</template>
						</tbody>
					</table>
				</div>
			</div>


			<div
				class=" mt-15 justify-space-between gap-20 mb-50"
			>
				<div class="bg-white flex-1 pa-10">
					<div class="justify-space-between">
						<div class="justify-space-between items-center">
							<h6 class="mr-10">최근 문의</h6>
						</div>
						<div>
							<button
								v-for="(item, i_index) in items"
								:key="'qna_site' + item.site_id"
								class="btn  mr-10"
								:class="i_index == qna_index ? 'btn-primary' : 'btn-default'"
								@click="qna_index = i_index"
							>{{ item.site_name }}</button>
						</div>
					</div>
					<template
						v-for="(item, i_index) in items"
					>
						<ul
							v-if="qna_index == i_index"
							class=""
							:key="'qna_' + i_index"
						>
							<template
								v-if="item.qna_items.length > 0"
							>
								<li
									v-for="qna in qna_item_list"
									:key="'qna_' + qna.uid"
									class="ptb-10 justify-space-between under-line-not-last"
									@click="toQnaDetail(qna, item)"
								>
									<span>[{{ qna.is_answer }}] {{  qna.b_title  }}</span>
									<span>{{  qna.wDate | transDate('-')}}</span>
								</li>
							</template>
							<li
								v-else
							>등록된 문의가 없습니다</li>
						</ul>
					</template>
				</div>
				<div class="bg-white flex-1 pa-10">
					<div class="justify-space-between">
						<div class="justify-space-between items-center">
							<h6 class="mr-10">공지사항</h6>
						</div>
						<div>
							<button
								v-for="(item, i_index) in items"
								:key="'qna_site' + item.site_id"
								class="btn  mr-10"
								:class="i_index == notice_index ? 'btn-primary' : 'btn-default'"
								@click="notice_index = i_index"
							>{{ item.site_name }}</button>
						</div>
					</div>
					<template
						v-for="(item, i_index) in items"
					>
						<ul
							v-if="notice_index == i_index"
							class=""
							:key="'notice_' + i_index"
						>
							<template
								v-if="item.notice_items.length > 0"
							>
								<li
									v-for="notice in item.notice_items"
									:key="'notice_' + notice.uid"
									class="ptb-10 justify-space-between under-line-not-last"
									@click="toNoticeDetail(notice, item)"
								>
									<span>{{  notice.b_title  }}</span>
									<span>{{  notice.wDate | transDate('-')}}</span>
								</li>
							</template>
							<li
								v-else
							>공지사항이 없습니다</li>
						</ul>
					</template>
				</div>
			</div>
			<div class="mt-auto pa-10 "></div>
		</div>
	</div>
</template>

<script>

import { GChart } from 'vue-google-charts'
import {Base64} from "js-base64";

export default{
	name: 'Main'
	,props: ['Axios', 'TOKEN', 'date', 'user', 'codes']
	,components: { GChart }
	,data: function(){
		return {
			program: {
				name: '대시보드'
				,top: true
				,title: true
				,search: false
				,bottom: false
			}
			,member_info: {

			}
			, search: {
				sDate: this.$date.getToday('-')
			}
			,items: []
			,item: {

			}
			,chartData: [
				['Month', '판매량', '매출', '판매량'],
				['2021.09.13',  165,      938,         522],
				['2021.09.14',  135,      1120,        599],
				['2021.09.15',  157,      1160,        587],
				['2021.09.16',  139,      1110,        615],
				['2021.09.17',  136,      691,         629]
			]
			,chartOptions1: {
				title : '최근 1주일 충전 판매량',
				height: 350,
				seriesType: 'bars'
				,vAxis: {format: 'short'}
			}
			,chartOptions2: {
				title : '최근 1주일 일반 상품 판매량',
				height: 350,
				seriesType: 'bars'
				,vAxis: {format: 'short'}
			}
			,chartOptions3: {
				title : '최근 1주일 기업 상품 판매량',
				height: 350,
				seriesType: 'bars'
				,vAxis: {format: 'short'}
			}
			,chart_data1: []
			,chart_data2: []
			,chart_data3: []
			,settlement_data: {
				total_cnt: 0
			}
			,qna_items: []
			,notice_items: []
			, items_order: []
			, item_charge: {
				charge_count: 0
				, charge_price: 0
				, charge_order_price: 0
			}
			, items_claim: [

			]
			, qna_index: 0
			, notice_index: 0
		}
	}
	,computed: {
		qna_item_list: function(){
			return this.items[this.qna_index].qna_items.filter(function(item){
				if(item.b_answer){
					item.is_answer = '답변완료'
				}else{
					item.is_answer = '답변대기'
				}
				return item
			})
		}
		,chart_data_1: function(){
			let d = ["판매일"]

			for(let i = 0; i < this.site_list.length; i++){
				d.push(this.site_list[i].site_name)
			}

			let data = [d]

			for(let i = 6; i >= 0; i--){
				let date = this.$date.getLastDate(i, '-')
				let d_data = []
				d_data.push(date)
				this.items.filter( (item, index) => {
					d_data[index + 1] = 0
					if(item.chart_data1.length > 0){
						item.chart_data1.filter( (data) => {
							if(date == data.date){
								d_data[index + 1] = Number(data.total_sale_price ? data.total_sale_price : 0)
							}
						})
					}
				})
				data.push(d_data)
			}
			console.log(data)

			return data
		}
		,chart_data_2: function(){
			let d = ["판매일"]

			for(let i = 0; i < this.site_list.length; i++){
				d.push(this.site_list[i].site_name)
			}

			let data = [d]

			for(let i = 6; i >= 0; i--){
				let date = this.$date.getLastDate(i, '-')
				let d_data = []
				d_data.push(date)
				this.items.filter( (item, index) => {
					d_data[index + 1] = 0
					if(item.chart_data2.length > 0){
						item.chart_data2.filter( (data) => {
							if(date == data.date){
								d_data[index + 1] = Number(data.total_sale_price ? data.total_sale_price : 0)
							}
						})
					}
				})
				data.push(d_data)
			}
			console.log(data)

			return data
		}
		,chart_data_3: function(){
			let d = ["판매일"]

			for(let i = 0; i < this.site_list.length; i++){
				d.push(this.site_list[i].site_name)
			}

			let data = [d]

			for(let i = 6; i >= 0; i--){
				let date = this.$date.getLastDate(i, '-')
				let d_data = []
				d_data.push(date)
				this.items.filter( (item, index) => {
					d_data[index + 1] = 0
					if(item.chart_data3.length > 0){
						item.chart_data3.filter( (data) => {
							if(date == data.date){
								d_data[index + 1] = Number(data.total_sale_price ? data.total_sale_price : 0)
							}
						})
					}
				})
				data.push(d_data)
			}
			console.log(data)

			return data
		}
		, site_list: function(){
			let t = []
			let site_list = sessionStorage.getItem('site_list')
			if(site_list){
				t = JSON.parse(Base64.decode(site_list))
			}
			return t
		}
		, item_total: function(){
			let t = {
				charge_count: 0
				, charge_price: 0
				, charge_order_price: 0
			}

			this.items.filter( (item) => {
				t.charge_count += Number(item.item_charge.charge_count)
				t.charge_price += Number(item.item_charge.charge_price)
				t.charge_order_price += Number(item.item_charge.charge_order_price)
			})

			return t
		}
	}
	,methods: {
		getData: async function(){

			let site_list = this.site_list
			let d = []
			if(site_list.length > 0){
				for(let i = 0; i < site_list.length; i++){

					const result = await this.$request.init({
						method: 'post'
						,url: 'management/getDashboard'
						,data: {
							site_info: site_list[i].site_id
						}
					})

					if(result.success){

						d.push({

							site_id: site_list[i].site_id
							, site_name: site_list[i].site_name
							, item: result.data
							, items_order: result.data.items_order
							, item_charge: result.data.item_charge
							, items_claim: result.data.items_claim

							, chart_data1: result.data.chart_data1
							, chart_data2: result.data.chart_data2
							, chart_data3: result.data.chart_data3
							, settlement_data: result.data.settlement_data
							, qna_items: result.data.qna_list
							, notice_items: result.data.notice_list
						})
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message})
					}
				}
			}

			this.items = d
		}
		,goDetail(item){
			item.TOKEN = sessionStorage.getItem('delimallAT')
			this.$set(this, 'item', item)
		}
		,clear(){
			this.$set(this, 'item', {})
			this.setProgram(this.program)
		}
		,setProgram(program){
			this.$emit('onLoad', program)
		}
		,setNotify({ type, message}){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		,toQnaDetail: function(item, site){
			sessionStorage.setItem('site_info', Base64.encode(JSON.stringify(site)))
			this.$router.push({ name: 'BbsAnswer', params: { b_code: item.b_code, bbs_uid: item.uid}})
		}
		,toNoticeDetail: function(item, site){
			sessionStorage.setItem('site_info', Base64.encode(JSON.stringify(site)))
			if(this.user.role == this.codes.type_code_admin){
				this.$router.push({ name: 'BbsDetail', params: { b_code: item.b_code, bbs_uid: item.uid}})
			}else{
				this.$router.push({ name: 'BbsView', params: { b_code: item.b_code, bbs_uid: item.uid}})
			}
		}
		, toQna: function(){
			this.$storage.push({ name: 'CustomerCenterQnAList'})
		}
		, toNotice: function(){
			this.$storage.push({ name: 'CustomerCenterNoticeList'})
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
		this.getData()
	}
}

</script>

<style>

.label { color: white; border-radius: 5px; padding: 3px 7px 3px; line-height: 12px !important; font-size: 10px;}
.label-new { background-color: #0D47A1;}
.label-hot { background-color: #e64a19;}
.label-recomm { background-color: #0f9d58;}
.label-season { background-color: #00b0ff;}
</style>
